import React from "react";
import PropTypes from "prop-types";
import {useTheme} from "@mui/material";
import CheckBox from "@mui/icons-material/CheckBox";
import SAMLIcon from "../../../resources/icons/SAMLIcon";
import {CenteredBox} from "../../../components/CenteredBox";
import {useNavigate} from "react-router-dom";
import StyledTooltip from "../../../components/tooltips/StyledTooltip";
import {useIntegrationStyles} from "../IntegrationStyles";

export const IntegrationCard = ({integration}) => {
  const {classes} = useIntegrationStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const logo =
    theme.palette.mode === "dark" ?
      integration?.logoDark ?? integration?.logo :
      integration.logo;

  return (
    <CenteredBox
      id={`integration-${integration.name}`}
      aria-label={`${integration.title} configuration`}
      className={classes.card}
      onClick={() => {
        navigate(`/integrations/${integration.name}`);
      }}
    >
      {integration?.configuration?.isEnabled && (
        <StyledTooltip title={`${integration?.title} is currently enabled`}>
          <CheckBox className={classes.isEnabledIcon} color="secondary" />
        </StyledTooltip>
      )}
      {integration.logo === "GENERICSAML" ?
        <SAMLIcon
          text="SAML Connector"
          fontSize={18}
          fontWeight={600}
          y="50%"
        /> :
        <img
          className={classes.logo}
          src={logo}
          style={{maxWidth: integration?.configuration?.logoMaxWidth}}
        />
      }
    </CenteredBox>
  );
};

IntegrationCard.propTypes = {
  integration: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    logo: PropTypes.string,
    logoDark: PropTypes.string,
    backgroundColor: PropTypes.string,
    configuration: PropTypes.object,
  }),
};
