import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";

const AssignmentBulkIcon = ({fontSize}) => (
  <SvgIcon viewBox="0 0 24 24" id="assignment-bulk-icon" fontSize={fontSize}>
    <g transform="translate(1, 1.5) scale(0.55)">
      <path d="M12 12c2.21 0 4-1.79 4-4S14.21 4 12 4 8 5.79 8 8s1.79 4 4 4z" />
      <path d="M12 14c-2.33 0-7 1.17-7 3.5V18h14v-.5c0-2.33-4.67-3.5-7-3.5z" />
    </g>
    <g transform="translate(1, 10) scale(0.55)">
      <path d="M12 12c2.21 0 4-1.79 4-4S14.21 4 12 4 8 5.79 8 8s1.79 4 4 4z" />
      <path d="M12 14c-2.33 0-7 1.17-7 3.5V18h14v-.5c0-2.33-4.67-3.5-7-3.5z" />
    </g>
    <path d="M14 7h6v2h-6z" />
    <path d="M14 13h6v2h-6z" />
  </SvgIcon>
);

AssignmentBulkIcon.propTypes = {
  fontSize: PropTypes.string,
};

export default AssignmentBulkIcon;
