import React, {useState} from "react";
import PropTypes from "prop-types";
import {isFunction} from "underscore";
import {IconButton} from "@mui/material";
import StyledTooltip from "../tooltips/StyledTooltip";
import {CachedOutlined} from "@mui/icons-material";
import {useRefetchButtonStyles} from "./buttonStyles";
import styles from "../../resources/scss/App.scss";

const RefetchButton = ({refetch, icon, tooltip}) => {
  const [refetching, setRefetching] = useState(false);
  if (!isFunction(refetch)) return null;
  const {classes} = useRefetchButtonStyles();
  const className =
    refetching ? `${classes.refetch} ${styles.spin}` : `${classes.refetch}`;
  const id = refetching ? "refetching" : "refetch";

  const goRefetch = async () => {
    setRefetching(true);
    try {
      await refetch();
    } finally {
      setRefetching(false);
    }
  };

  return (
    <span>
      <IconButton
        aria-label="refresh"
        id={id}
        onClick={goRefetch}
        className={className}
        size="large"
      >
        <StyledTooltip title={tooltip ? tooltip : "Reload"}>
          {icon ? icon : <CachedOutlined color="primary" />}
        </StyledTooltip>
      </IconButton>
    </span>
  );
};

RefetchButton.propTypes = {
  refetch: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  icon: PropTypes.object,
  tooltip: PropTypes.string,
};

export default RefetchButton;
