import React, {createContext, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {LoginForm} from "../LoginForm";
import ForgotPasswordForm from "../ForgotPasswordForm";
import NewUserPasswordForm from "../NewUserPasswordForm";
import MFAChallengeForm from "../MFA/MFAChallengeForm";
import {
  setCookieSession,
  getCookieSession,
  clearCookieSession,
} from "../../../resources/cookies";
import {fromBase64URL} from "../../../resources/utils/encoding";
import {getAllProviderLogins} from "./authProvidersHelpers";
import {identity, isEmpty, isString, pick} from "underscore";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const AuthContext = createContext(undefined);

const challengeComponentMap = {
  NEW_PASSWORD_REQUIRED: <NewUserPasswordForm />,
  SMS_MFA: <MFAChallengeForm mfaMethod="SMS_MFA" />,
  SOFTWARE_TOKEN_MFA: <MFAChallengeForm mfaMethod="SOFTWARE_TOKEN_MFA" />,
};

const AuthProvider = ({children}) => {
  const orgName = window.location.hostname.split(".").shift();
  const [authSession, setAuthSession] = useState(getCookieSession());
  const [altAuthProviders, setAltAuthProviders] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loginComponent, setLoginComponent] = useState();
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [isAltAuthLoading, setIsAltAuthLoading] = useState(true);

  useEffect(async () => {
    if (altAuthProviders) return;
    const providers = await getAllProviderLogins(orgName);
    setAltAuthProviders(providers);
  }, []);

  useEffect(() => {
    if (!altAuthProviders) return;
    setIsAltAuthLoading(false);
  }, [altAuthProviders]);

  const resetAuthProviders = async () => {
    const providers = await getAllProviderLogins(orgName);
    setAltAuthProviders(providers);
  };

  const login = async (data) => {
    const {username, password} = data;
    setIsAuthLoading(true);
    setErrorMessage("Logging in...");
    try {
      const response = await axios.post(
        `${AUTH_ENDPOINT}/login`,
        {orgName},
        {auth: {username, password}},
      );

      if (response.status === 200) {
        setAuthSession({
          ...response.data,
          rememberMe: data.rememberMe,
          username,
        });
        setErrorMessage("");
      } else {
        setErrorMessage("Login unsuccessful.");
      }
    } catch (error) {
      if (error?.message.match(/426/i)) {
        setErrorMessage("One time configuration change. Please log in again.");
      } else {
        if (
          (error?.message ?? error.response?.data?.message) === "Network Error"
        ) {
          setErrorMessage("Login unsuccessful");
        } else {
          setErrorMessage(
            error.response?.data?.message ??
              error?.message ??
              "Invalid credentials",
          );
        }
      }
    } finally {
      setIsAuthLoading(false);
    }
  };

  const logout = async () => {
    clearCookieSession();
    setAuthSession();
  };

  const refreshSession = async () => {
    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/token/refresh`, {
        orgName,
        AuthParameters: {
          REFRESH_TOKEN: authSession.RefreshToken,
          USERNAME: authSession.username,
        },
      });

      if (response.status === 200) {
        setAuthSession({...authSession, ...response.data});
        return response.data;
      } else {
        logout();
      }
    } catch (error) {
      logout();
    }
  };

  const providerValues = {
    orgName,
    errorMessage,
    authSession,
    isAuthLoading,
    setIsAuthLoading,
    setAuthSession,
    setErrorMessage,
    login,
    refreshSession,
    logout,
    setLoginComponent,
    altAuthProviders,
    hasAuthProviders: !isEmpty(pick(altAuthProviders, identity)),
    resetAuthProviders,
    isAltAuthLoading,
    setIsAltAuthLoading,
  };

  useEffect(async () => {
    const confirmationCode = new URLSearchParams(window.location.search).get(
      "confirmationCode",
    );
    if (confirmationCode && !authSession) {
      setLoginComponent(<ForgotPasswordForm />);
      return;
    }

    const code = new URLSearchParams(window.location.search).get("code");
    if (code && !authSession) {
      const response = await axios.post(`${AUTH_ENDPOINT}/login/token`, {
        orgName,
        code,
      });

      const state = new URLSearchParams(window.location.search).get("state");
      const returnLink =
        !isEmpty(state) ? fromBase64URL(state) : window.location.pathname;

      window.history.replaceState(null, "", returnLink);
      const rememberMe = window.sessionStorage.getItem("rememberMe") === "true";
      setAuthSession({...response.data, rememberMe});
      return;
    }

    if (!authSession) {
      const errorInURL = new URLSearchParams(window.location.search).get(
        "error_description",
      );
      if (isString(errorInURL) && !isEmpty(errorInURL)) {
        setErrorMessage(errorInURL);
      }
      setLoginComponent(<LoginForm />);
      return;
    }

    const {AccessToken: accessToken, ChallengeName: challengeName} =
      authSession;

    if (accessToken) {
      setLoginComponent(children);
      if (authSession.rememberMe === true) setCookieSession(authSession);
      else {
        const cookies = getCookieSession();
        if (cookies?.IdToken && cookies?.IdToken !== authSession.IdToken) {
          setCookieSession(authSession);
          window.location.reload();
        }
      }
      return;
    }

    if (challengeName) {
      setLoginComponent(challengeComponentMap[challengeName]);
      return;
    }
  }, [authSession]);

  return (
    <AuthContext.Provider value={providerValues}>
      {loginComponent}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAuth = () => {
  return useContext(AuthContext);
};

export {AuthContext, AuthProvider, useAuth};
