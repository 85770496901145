import {gql} from "@apollo/client";

export const GET_INTEGRATIONS = gql`
  query GetIntegrations {
    integrations {
      name
      configuration
    }
    jira: __type(name: "JiraIntegrationInput") {
      inputFields {
        name
      }
    }
    jiraservicemanagement: __type(
      name: "JiraServiceManagementIntegrationInput"
    ) {
      inputFields {
        name
      }
    }
    msteams: __type(name: "MSTeamsIntegrationInput") {
      inputFields {
        name
      }
    }
    opsgenie: __type(name: "OpsGenieIntegrationInput") {
      inputFields {
        name
      }
    }
    pagerduty: __type(name: "PagerDutyIntegrationInput") {
      inputFields {
        name
      }
    }
    revelstoke: __type(name: "RevelstokeIntegrationInput") {
      inputFields {
        name
      }
    }
    salesforce: __type(name: "SalesforceIntegrationInput") {
      inputFields {
        name
      }
    }
    slack: __type(name: "SlackIntegrationInput") {
      inputFields {
        name
      }
    }
    vanta: __type(name: "VantaIntegrationInput") {
      inputFields {
        name
      }
    }
    xsoar: __type(name: "XsoarIntegrationInput") {
      inputFields {
        name
      }
    }
    zoom: __type(name: "ZoomIntegrationInput") {
      inputFields {
        name
      }
    }
  }
`;

export const UPDATE_MSTEAMS_INTEGRATION = gql`
  mutation UpdateMSTeamsIntegration($webhook: String, $isEnabled: Boolean) {
    updateMSTeamsIntegration(data: {webhook: $webhook, isEnabled: $isEnabled}) {
      name
      configuration
    }
  }
`;

export const DELETE_MSTEAMS_INTEGRATION = gql`
  mutation DeleteMSTeamsIntegration {
    deleteMSTeamsIntegration {
      name
      configuration
    }
  }
`;

export const UPDATE_JIRA_SERVICE_MANAGEMENT_INTEGRATION = gql`
  mutation UpdateJiraServiceManagementIntegration(
    $subdomain: String!
    $apiEmail: String!
    $apiKey: String!
    $serviceDeskId: String!
    $requestTypeId: String!
    $isEnabled: Boolean
  ) {
    updateJiraServiceManagementIntegration(
      data: {
        subdomain: $subdomain
        apiEmail: $apiEmail
        apiKey: $apiKey
        serviceDeskId: $serviceDeskId
        requestTypeId: $requestTypeId
        isEnabled: $isEnabled
      }
    ) {
      name
      configuration
    }
  }
`;

export const DELETE_JIRA_SERVICE_MANAGEMENT_INTEGRATION = gql`
  mutation DeleteJiraServiceManagementIntegration {
    deleteJiraServiceManagementIntegration {
      name
      configuration
    }
  }
`;

export const UPDATE_PAGERDUTY_INTEGRATION = gql`
  mutation UpdatePagerDutyIntegration(
    $apiKey: String
    $serviceId: String
    $escalationPolicyId: String
    $fromEmail: String
    $isEnabled: Boolean
  ) {
    updatePagerDutyIntegration(
      data: {
        apiKey: $apiKey
        serviceId: $serviceId
        escalationPolicyId: $escalationPolicyId
        fromEmail: $fromEmail
        isEnabled: $isEnabled
      }
    ) {
      name
      configuration
    }
  }
`;

export const DELETE_PAGERDUTY_INTEGRATION = gql`
  mutation DeletePagerDutyIntegration {
    deletePagerDutyIntegration {
      name
      configuration
    }
  }
`;

export const UPDATE_OPSGENIE_INTEGRATION = gql`
  mutation UpdateOpsGenieIntegration(
    $apiKey: String
    $serviceId: String
    $isEnabled: Boolean
  ) {
    updateOpsGenieIntegration(
      data: {apiKey: $apiKey, serviceId: $serviceId, isEnabled: $isEnabled}
    ) {
      name
      configuration
    }
  }
`;

export const DELETE_OPSGENIE_INTEGRATION = gql`
  mutation DeleteOpsGenieIntegration {
    deleteOpsGenieIntegration {
      name
      configuration
    }
  }
`;

export const UPDATE_REVELSTOKE_INTEGRATION = gql`
  mutation UpdateRevelstokeIntegration($apiKey: String, $isEnabled: Boolean) {
    updateRevelstokeIntegration(
      data: {apiKey: $apiKey, isEnabled: $isEnabled}
    ) {
      name
      configuration
    }
  }
`;

export const DELETE_REVELSTOKE_INTEGRATION = gql`
  mutation DeleteRevelstokeIntegration {
    deleteRevelstokeIntegration {
      name
      configuration
    }
  }
`;

export const UPDATE_SLACK_INTEGRATION = gql`
  mutation UpdateSlackIntegration(
    $identifier: String
    $conversationId: String
    $isEnabled: Boolean
  ) {
    updateSlackIntegration(
      data: {
        identifier: $identifier
        conversationId: $conversationId
        isEnabled: $isEnabled
      }
    ) {
      name
      configuration
    }
  }
`;

export const DELETE_SLACK_INTEGRATION = gql`
  mutation DeleteSlackIntegration {
    deleteSlackIntegration {
      name
      configuration
    }
  }
`;

export const UPDATE_XSOAR_INTEGRATION = gql`
  mutation UpdateXsoarIntegration($apiKey: String, $isEnabled: Boolean) {
    updateXsoarIntegration(data: {apiKey: $apiKey, isEnabled: $isEnabled}) {
      name
      configuration
    }
  }
`;

export const DELETE_XSOAR_INTEGRATION = gql`
  mutation DeleteXsoarIntegration {
    deleteXsoarIntegration {
      name
      configuration
    }
  }
`;

export const GET_ORG_DETAILS = gql`
  query GetOrgDetails {
    orgDetails
  }
`;
