import React from "react";
import PropTypes from "prop-types";
import {useNavigate, useLocation} from "react-router-dom";
import {Button} from "@mui/material";
import {useCancelStyles} from "./buttonStyles";

const CancelButton = ({
  children,
  disabled,
  fallbackTo,
  margin,
  forceFallback,
  onCancel,
  variant,
}) => {
  const {classes} = useCancelStyles({margin: margin, disabled: disabled});
  const navigate = useNavigate();
  const location = useLocation();
  const ignoreLocation = variant === "modal" || variant === "inline";

  const onClick = () => {
    if (onCancel) onCancel();
    if (location.key && !ignoreLocation && !forceFallback) navigate(fallbackTo);
    else if ((fallbackTo && !ignoreLocation) || (fallbackTo && forceFallback)) {
      navigate(fallbackTo);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          type="button"
          id="cancel"
          variant="outlined"
          className={classes.cancelButton}
          onClick={onClick}
          disabled={disabled}
        >
          {children || "Cancel"}
        </Button>
      </div>
    </div>
  );
};

CancelButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  fallbackTo: PropTypes.string,
  forceFallback: PropTypes.bool,
  margin: PropTypes.object,
  onCancel: PropTypes.func,
  variant: PropTypes.string,
};

export default CancelButton;
