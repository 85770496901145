import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";

const AcceptBulkIcon = ({fontSize}) => (
  <SvgIcon viewBox="0 0 24 24" id="accept-bulk-icon" fontSize={fontSize}>
    <g transform="translate(0, 2.5) scale(0.7 0.8)">
      <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-7-.25c.22 0 .41.1.55.25.12.13.2.31.2.5 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-.19.08-.37.2-.5.14-.15.33-.25.55-.25M19 19H5V5h14zM12 6c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3m0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m-6 6.47V18h12v-1.53c0-2.5-3.97-3.58-6-3.58s-6 1.07-6 3.58M8.31 16c.69-.56 2.38-1.12 3.69-1.12s3.01.56 3.69 1.12z" />
    </g>
    <g transform="translate(3, 0)">
      <path d="M14 7h6v2h-6z" />
      <path d="M14 13h6v2h-6z" />
    </g>
  </SvgIcon>
);

AcceptBulkIcon.propTypes = {
  fontSize: PropTypes.string,
};

export default AcceptBulkIcon;
