import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Divider, Typography, useTheme} from "@mui/material";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import {useAuth} from "./Context/AuthProvider";
import {toBase64URL} from "../../resources/utils/encoding";
import {useAuthStyles} from "./AuthStyles";
import {isEmpty, map} from "underscore";

export const ProviderLogins = () => {
  const {altAuthProviders, hasAuthProviders} = useAuth();
  const {classes} = useAuthStyles({
    authProviderCount: (altAuthProviders ?? [])?.length || 0,
  });
  const theme = useTheme();

  const storeRememberMePrefAndRedirect = (url) => {
    window.sessionStorage.setItem("rememberMe", "true");
    const state =
      window.location.pathname.length > 1 ?
        `&state=${toBase64URL(window.location.pathname)}` :
        "";
    window.location.href = `${url}${state}`;
  };

  if (!hasAuthProviders || isEmpty(altAuthProviders)) return null;

  const ProviderButton = ({icon, provider}) => {
    const genericSAML = provider.type === "genericSAML" ? "generic-saml-" : "";
    return (
      <Button
        variant="outlined"
        size="large"
        className={classes.providerButton}
        fullWidth
        onClick={() => storeRememberMePrefAndRedirect(provider.url)}
        id={`${genericSAML}${provider.displayName.toLowerCase()}-provider-login`}
      >
        {icon === "GENERICSAML" ?
          <LoginOutlinedIcon sx={{marginRight: 0.5}} /> :
          <img className={classes.providerLogo} src={icon} />}
        {provider.displayName}
      </Button>
    );
  };

  ProviderButton.propTypes = {
    icon: PropTypes.any,
    provider: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      displayName: PropTypes.string,
    }),
  };

  const oneLoginLogoDetail =
    theme.palette.mode === "dark" ?
      require("~/public/integrations/onelogin-detail.png") :
      require("~/public/integrations/onelogin-detail-reverse.png");

  const getProviderIcon = (providerName) => {
    if (`${providerName}`.startsWith("GENERICSAML-")) {
      return "GENERICSAML";
    }
    switch (providerName) {
      case "GoogleSAML":
        return require("~/public/integrations/google-detail.svg");
      case "MSEntra":
        return require("~/public/integrations/microsoft-detail.png");
      case "Okta":
        return require("~/public/integrations/okta-detail.png");
      case "OneLogin":
        return oneLoginLogoDetail;
      case "Ping":
        return require("~/public/integrations/ping-detail.png");
      default:
        return null;
    }
  };

  return (
    <>
      <Divider sx={{marginTop: 2}}>
        <Typography variant="caption">Sign in with</Typography>
      </Divider>
      <Box py={3} className={classes.buttonRow}>
        {map(altAuthProviders, (provider) => (
          <ProviderButton
            key={provider.name}
            icon={getProviderIcon(provider.name)}
            provider={provider}
          />
        ))}
      </Box>
      <Divider>
        <Typography variant="caption">or</Typography>
      </Divider>
    </>
  );
};
