import React, {useState} from "react";
import PropTypes from "prop-types";
import {IconButton, TextField} from "@mui/material";
import {Clear, Search} from "@mui/icons-material";
import {isString} from "underscore";
import {useSearchStyles} from "./searchStyles";

const TextSearch = ({
  currentValue = "",
  autoFocus,
  className,
  disabled,
  fullWidth,
  id,
  onChange,
  placeholder,
  size,
  style,
  variant,
}) => {
  const [value, setValue] = useState(currentValue);
  const {classes} = useSearchStyles();

  const onTextChange = (event) => {
    const text = isString(event.target.value) ? event.target.value : "";
    setValue(text);
    onChange && onChange(text);
  };

  return (
    <TextField
      value={value}
      onChange={onTextChange}
      autoFocus={autoFocus}
      className={className ?? classes.noPrint}
      disabled={disabled}
      fullWidth={fullWidth}
      id={id ?? "text-search"}
      placeholder={placeholder ?? "Type to search"}
      size={size}
      style={style}
      variant={variant ?? "standard"}
      InputProps={{
        startAdornment: (
          <Search
            fontSize="small"
            color={value !== "" ? "primary" : undefined}
          />
        ),
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            id="clear-search"
            style={{visibility: value ? "visible" : "hidden"}}
            onClick={() => onTextChange({target: {value: ""}})}
          >
            <Clear fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};

TextSearch.propTypes = {
  currentValue: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.any,
  variant: PropTypes.string,
};

export default TextSearch;
