// none of these change default behavior, they just suppress warnings
export default {
  Incident: {
    fields: {
      types: {
        merge(_, incoming) {
          return incoming;
        },
      },
      attributes: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  Type: {
    fields: {
      incidents: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  IncidentSeverity: {
    fields: {
      incidents: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  Obligations: {
    fields: {
      tasks: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  TaskData: {
    fields: {
      obligations: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  Query: {
    fields: {
      incidents: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  Action: {
    fields: {
      roles: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
};
