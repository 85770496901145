import React from "react";
import PropTypes from "prop-types";

const SAMLIcon = ({
  fontSize = 32,
  fontWeight,
  text = "SAML 2.0",
  x = "50%",
  y = "25%",
}) => (
  <svg data-testid="saml-icon" width={150} height={100}>
    <text
      x={x}
      y={y}
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#4260d2"
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily="Roboto"
    >
      {text}
    </text>
  </svg>
);

SAMLIcon.propTypes = {
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  text: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
};

export default SAMLIcon;
