import React, {useState, createContext, useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
import {useFormContext} from "react-hook-form";
import {useQuery} from "@apollo/client";
import {GET_INTEGRATIONS} from "../../../graphql/integrationQueries";
import {ALERT} from "../../../resources/environment";
import {emitStartStopMetric} from "../../../resources/metrics";
import {handleError} from "../../../resources/utils/error";
import {integrationData} from "../Data/integrationData";
import {useAuth} from "../../../components/authentication/Context/AuthProvider";
import {deepFindValueForKey} from "../../../resources/utils/objectUtils";
import {
  find,
  findLastIndex,
  findWhere,
  isEmpty,
  isObject,
  map,
} from "underscore";

export const IntegrationsContext = createContext(null);

export const IntegrationsProvider = ({children}) => {
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [integrations, setIntegrations] = useState(integrationData);
  const [initialized, setInitialized] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [selectedFormData, setSelectedFormData] = useState(undefined);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const {altAuthProviders} = useAuth();
  const validIntegrationPaths = integrations.map(
    ({name}) => `/integrations/${name}`,
  );
  const integrationFormMethods = useFormContext();

  const initializeIntegrationFields = (data = integrations) => {
    const currentIntegrations = [...integrations];
    data?.integrations?.map((integration) => {
      const match = findWhere(currentIntegrations, {name: integration?.name});
      if (!match) return;
      data?.[integration.name]?.inputFields?.map((field) => {
        const name = field.name;
        const value = integration.configuration[name];
        match.configuration[name] = value === "false" ? false : value;
      });
    });

    const updateAuthProvidersStatus = () => {
      return map(currentIntegrations, (integration, index) => {
        const authProvider = find(altAuthProviders, (provider) => {
          const integrationName = `${integration.name}`.toLowerCase();
          const matchedByName = [
            `${provider.displayName}`.toLowerCase(),
            `${provider.name}`.toLowerCase(),
          ].includes(integrationName);
          const matchedByType =
            integrationName === "genericsaml" &&
            integrationName === `${provider?.type}`.toLowerCase();
          return matchedByName || matchedByType;
        });

        if (!authProvider) return;
        currentIntegrations[index].configuration.isEnabled = !isEmpty(
          authProvider?.url,
        );
      });
    };

    updateAuthProvidersStatus();

    const xsoarIndex = findLastIndex(currentIntegrations, {name: "xsoar"});
    if (
      xsoarIndex >= 0 &&
      isObject(currentIntegrations[xsoarIndex]?.configuration)
    ) {
      currentIntegrations[xsoarIndex].configuration.isEnabled =
        !!currentIntegrations[xsoarIndex].configuration.apiKey;
    }

    const revelstokeIndex = findLastIndex(currentIntegrations, {
      name: "revelstoke",
    });
    if (
      revelstokeIndex >= 0 &&
      isObject(currentIntegrations[revelstokeIndex]?.configuration)
    ) {
      currentIntegrations[revelstokeIndex].configuration.isEnabled =
        !!currentIntegrations[revelstokeIndex].configuration.apiKey;
    }

    setIntegrations(currentIntegrations);
    setInitialized(true);
  };

  const {loading, error, data} = useQuery(GET_INTEGRATIONS, {
    onCompleted: (data) => initializeIntegrationFields(data),
    onError: (error) => handleError(error, setSubmitting),
  });

  const setIntegrationFromPath = (path) => {
    const integrationName = path.split("/")[2];
    const integrationFromPath = findWhere(integrations, {
      name: integrationName,
    });
    setSelectedIntegration(integrationFromPath);
  };

  useEffect(() => {
    if (!data || !validIntegrationPaths.includes(location.pathname)) {
      return;
    }
    setIntegrationFromPath(location.pathname);
  }, [loading, data]);

  useEffect(() => {
    if (!isEmpty(data) && selectedIntegration) {
      setSelectedFormData({...selectedIntegration.configuration});
    }
  }, [selectedIntegration, data, loading]);

  const updateIntegrationFields = (
    configuration,
    isDeleting = false,
    useNewPath = false,
  ) => {
    const currentIntegrations = [...integrations];

    const name = selectedIntegration?.name;
    const deepName = deepFindValueForKey(configuration, "name");
    const nameToUse = useNewPath ? deepName : name;
    if (!nameToUse) return;

    const match = findWhere(currentIntegrations, {name: nameToUse});
    if (!match) return;

    const deepConfiguration = deepFindValueForKey(
      configuration,
      "configuration",
    );
    if (useNewPath) {
      match.configuration = {
        ...match.configuration,
        ...(deepConfiguration ?? {}),
      };
    } else match.configuration = configuration;

    setIntegrations(currentIntegrations);
    const action = isDeleting ? "deleted" : "updated";
    const titleToDisplay = selectedIntegration?.title ?? "This";
    ALERT.success(
      `${titleToDisplay} integration configuration ${action} successfully`,
    );
    isDeleting ? setDeleting(false) : setSubmitting(false);
  };

  const initializeForm = (schema, defaultValues, onSubmit, onDelete) => {
    selectedIntegration.schema = schema;
    selectedIntegration.defaultValues = defaultValues;
    selectedIntegration.onSubmit = async (formData) => {
      await onSubmit({variables: formData});
    };
    selectedIntegration.onDelete = async () => {
      await onDelete();
    };
  };

  useEffect(() => {
    return emitStartStopMetric("view", {location});
  }, []);

  return (
    <IntegrationsContext.Provider
      value={{
        submitting,
        setSubmitting,
        deleting,
        setDeleting,
        integrations,
        selectedIntegration,
        setSelectedIntegration,
        loading: loading && !initialized,
        error,
        updateIntegrationFields,
        initializeForm,
        integrationFormMethods,
        selectedFormData,
        setSelectedFormData,
        searchText,
        setSearchText,
        altAuthProviders,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

IntegrationsProvider.propTypes = {
  children: PropTypes.any,
};

export const useIntegrations = () => {
  return useContext(IntegrationsContext);
};
