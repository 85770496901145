import axios from "axios";
import {findWhere, map, sortBy} from "underscore";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const getProviderDisplayName = (name) => {
  if (`${name}`.startsWith("GENERICSAML-")) {
    return {
      name,
      displayName: `${name}`.replace("GENERICSAML-", "").replace(/-/g, " "),
      type: "genericSAML",
    };
  }
  const mappedProvider = findWhere(
    [
      {name: "GoogleSAML", displayName: "Google", type: "SAML"},
      {name: "MSEntra", displayName: "Microsoft", type: "SAML"},
      {name: "OneLogin", displayName: "OneLogin", type: "SAML"},
      {name: "Okta", displayName: "Okta", type: "OIDC"},
      {name: "Ping", displayName: "Ping", type: "SAML"},
    ],
    {name: name},
  );
  return mappedProvider;
};

export const getAllProviderLogins = async (orgName) => {
  try {
    const response = await axios.post(
      `${AUTH_ENDPOINT}/login/redirects`,
      {orgName},
      {
        timeout: 10000,
        validateStatus: (status) => [200, 400, 404].includes(status) || !status,
      },
    );
    const providers = response?.data || [];

    const transformedProviders = map(providers, (provider) => ({
      ...provider,
      ...getProviderDisplayName(provider.name),
    }));

    return sortBy(transformedProviders, (item) =>
      item.type === "genericSAML" ? 1 : 0,
    );
  } catch (error) {
    console.error("Error fetching provider logins:", error);
    return [];
  }
};
