import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import StyledTooltip from "../tooltips/StyledTooltip";
import {Button} from "@mui/material";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import {EmptySpinningHourglass} from "../icons";
import {useAICooldown} from "../../context/AICooldownProvider";
import {isFunction} from "underscore";

const AIButton = ({
  onClick,
  text,
  tooltip,
  buttonProps,
  cooldown = 10,
  disabled,
  hidden,
  isLoading,
}) => {
  const {cooldownLeft, setCooldownLeft} = useAICooldown();

  if (!text || hidden) return null;

  const handleClick = async () => {
    if (!isFunction(onClick)) return;
    if (isLoading) return;
    await onClick();
    setCooldownLeft(cooldown);
  };

  const cooldownSeconds =
    cooldownLeft > 0 ? ` (available in ${cooldownLeft} seconds)` : "";

  return (
    <StyledTooltip
      placement="top"
      title={
        tooltip?.replace("$cooldownSeconds", cooldownSeconds) ??
        `${text} using Rex AI™${cooldownSeconds}`
      }
    >
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClick}
        id={`${text}`.toLowerCase()}
        disabled={cooldownLeft > 0 || disabled}
        {...buttonProps}
      >
        {isLoading ?
          <EmptySpinningHourglass
            fontSize="small"
            color="secondary"
            sx={{marginRight: 0.5}}
          /> :
          <AutoAwesomeOutlinedIcon fontSize="small" sx={{marginRight: 0.5}} />}
        {text}
      </Button>
    </StyledTooltip>
  );
};

AIButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  buttonProps: PropTypes.object,
  cooldown: PropTypes.number,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default AIButton;
